<template>
    <div>
        <el-upload
            v-if="photoFile === null || this.photoImage !== null"
            ref="upload"
            :show-file-list="false"
            :on-change="handleChange"
            :on-remove="handleRemove"
            :file-list="fileList"
            :auto-upload="false"
            :limit="1"
            drag
            action=""
            :on-exceed="imageOverLimit"
            accept=".jpg,.jpeg,.png,.JPG,.JPEG"
        >
            <div class="relative w-full">
                <!-- 呈現字串得上傳框 -->
                <div
                    v-if="photoFile === null && photoImage === null"
                    class="text-3xl font-light"
                >
                    <i class="mr-1 el-icon-circle-plus-outline"></i>上傳存摺影本
                </div>
                <div v-if="photoImage !== null" class="p-2">
                    <img class="w-full rounded-lg" :src="photoImage" alt="" />
                </div>
            </div>
            <div slot="tip" class="text-center text-gray-300 el-upload__tip">
                只能上傳jpg/png文件，且不超過5mb
            </div>
        </el-upload>
    </div>
</template>

<script>
import { mapState } from "vuex";
// 取得 Localstorage值
import { getStorage } from "@/service/localStorage";
/**
 * 判斷圖片是否有載入失敗
 */
import { imageLoadError } from "@/service/anyService";
export default {
    name: "ProviderSalaryPhoto",
    props: {
        isProvider: {
            type: Boolean,
            default: true,
        },
        userId: {
            type: [String, Number],
            default: null,
        },
    },
    computed: {
        ...mapState("providerStore", ["providerData"]),
        ...mapState("memberStore", ["memberData"]),
        ...mapState("utilityStore", ["uiSwitchSettingDatas"]),
    },
    data() {
        return {
            // 上傳圖片檔
            photoFile: null,
            // 上傳圖片 base64
            photoImage: null,
            // 檔案列表
            fileList: [],
            loading: false,
        };
    },
    methods: {
        //圖片變更
        async handleChange(file) {
            // 清空上傳檔案列表 以免造成檔案上傳上限超過數量限制
            this.fileList = [];
            if (
                file.size >
                (this.uiSwitchSettingDatas.upload_photo_size ??
                    Number(process.env.VUE_APP_UPLOAD_PHOTO_SIZE)) *
                    1024 *
                    1024
            ) {
                this.$message({
                    type: "error",
                    message: `圖片尺寸不能大於 ${
                        this.uiSwitchSettingDatas.upload_photo_size ??
                        process.env.VUE_APP_UPLOAD_PHOTO_SIZE
                    }MB`,
                });
                return;
            }
            // 設定圖片為上傳檔案
            this.photoFile = file.raw;
            this.photoImage = await this.toBase64(this.photoFile);
            // 判斷是服務商時才執行
            if (this.isProvider) {
                this.upLoadPhoto(this.photoFile);
            }
        },
        // 將圖片轉為 base64
        toBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        },
        //移除圖片
        handleRemove() {
            this.photoFile = null;
        },
        //上傳圖片超過限制數量
        imageOverLimit(files, fileList) {
            console.log(fileList);
            this.$message({
                type: "error",
                message: "超過上傳圖片上限",
            });
        },
        /**
         * 上傳圖片
         * @param { type Blob or File(Blob 或 檔案格式) } file
         */
        async upLoadPhoto(file) {
            this.loading = true;
            try {
                const result = new FormData();
                result.append("image", file);
                const { data } = await this.$api.UplaodUserBankAccountPhotoApi(
                    this.$route.params.id,
                    result
                );
                this.loading = false;
                // 因為存摺有 jwt 的限制 因此需要加上 token
                this.photoImage =
                    data.passbook +
                    "&token=" +
                    getStorage("token").replace("Bearer ", "");
                this.$message({
                    type: "success",
                    message: "上傳圖片成功",
                });
                // 上傳成功時回傳成功給父組件
                this.$emit("onUploadBankPhotoSuccess", true);
            } catch (err) {
                this.loading = false;
                // 上傳失敗時回垂失敗給父組件
                this.$emit("onUploadBankPhotoSuccess", false);
                this.$message({
                    type: "error",
                    message: "上傳圖片失敗",
                });
            }
        },
        /**
         * 上傳城市聯盟存摺照
         */
        uploadSalesmanBankAccountPhoto() {
            this.$emit("update:salesmanBankAccountPhoto", this.photoFile);
        },
        // 表單發送
        validate() {
            // 判斷是否有上傳存摺照片
            if (this.photoImage === null) {
                // 沒有上傳封面照結果回傳給父組件
                this.$emit("onUploadBankPhotoSuccess", false);
                this.$message({
                    type: "error",
                    message: "尚未上傳存摺",
                });
                return false;
            }
            return true;
        },
        // 取得使用者訂單撥款照片
        async getOrderPassbookPhoto() {
            // 請求存摺照片
            let data = "";
            try {
                if (this.isProvider) {
                    // 請求存摺照片
                    data = this.providerData.passbook;
                } else {
                    // 請求存摺照片
                    data = this.memberData.passbook;
                }
                await this.checkImageLoad(data);
            } catch (err) {
                this.$message({
                    type: "error",
                    message: "取得存摺失敗",
                });
            }
        },
        /**
         * 取得城市聯盟撥款照片
         */
        async getSalesmanPassbookPhoto(userId) {
            // 請求存摺照片
            let data = "";
            try {
                if (this.isProvider) {
                    if (this.providerData.promotee !== null) {
                        // 請求存摺照片
                        data = this.providerData.promotee.passbook;
                    }
                } else {
                    if (this.memberData.promotee !== null) {
                        // 請求存摺照片
                        data = this.memberData.promotee.passbook;
                    }
                }
                await this.checkImageLoad(data);
            } catch (err) {
                console.log(err);
                this.$message({
                    type: "error",
                    message: "取得城市聯盟存摺失敗",
                });
            }
        },
        /**
         * 判斷是否為圖片檔
         */
        async checkImageLoad(data) {
            // 判斷是否有存摺照片
            const checkImageLoad = await imageLoadError(
                data + "&token=" + getStorage("token").replace("Bearer ", "")
            );
            // 判斷是否有存摺照片
            if (checkImageLoad) {
                // 將存摺網址 有 jwt 的限制 因此需要加上 token 且不能有 Bearer 字串
                this.photoImage =
                    data +
                    "&token=" +
                    getStorage("token").replace("Bearer ", "");
            } else {
                // 沒有存摺照片時回傳 null
                this.photoImage = null;
            }
        },
    },
    created() {
        // 取得使用者訂單撥款照片
        // this.getOrderPassbookPhoto();
        // 取得城市聯盟撥款照片
        // this.getSalesmanPassbookPhoto();
    },
};
</script>

<style lang="scss" scoped>
::v-deep .el-upload-dragger {
    @apply min-h-[500px] flex w-full items-center justify-center;
}
::v-deep .el-upload-dragger {
    @apply h-auto w-full flex-grow overflow-visible;
}
::v-deep .el-upload {
    @apply w-full;
}
::v-deep .el-dialog {
    @screen sm {
        @apply w-[500px];
    }
    @apply w-auto;
}
</style>
