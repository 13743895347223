/**
 * firebaseChatRoomUserKeys: firebase 取得所有聊天對象 banana_id
 * firebaseChatRoomUsersDataUpdate: firebase 更新有關於自身的聊天對象中屬於自己的 userData 資料
 * firebaseFilterSaveUserData: 過濾多於得使用者資料 將過濾後資料存入 firebase
 */
// import { firebaseChatRoomUserKeys, firebaseChatRoomUsersDataUpdate, firebaseFilterSaveUserData } from "@/service/firebaseChatSet";
import { firebaseChatRoomUsersDataUpdate, firebaseUpdateChatRoomUserData, firebaseChatRoomUserKeys } from "@/service/firestoreService";

export default {
    methods: {
        // /**
        //  * 更新 firebase realtime database 聊天對象資料
        //  * @param { type String(字串) } userId 目前更新對象的 banana_id
        //  * @param { type Object(物件) } userData 更新完後最新的使用者資料
        //  */
        // async updateFirebaseChatRoomUserData(userId, userData) {
        //     const chatRoomUsers = await firebaseChatRoomUserKeys(userId);
        //     // 判斷有使用者資料時 才往下執行更新
        //     if (chatRoomUsers === null) {
        //         return;
        //     }
        //     // 過濾使用者資料
        //     const filterUserData = await firebaseFilterSaveUserData(userData);
        //     // 須更新的聊天對象路徑
        //     const updateDatas = {};
        //     Object.keys(chatRoomUsers).forEach((objKey) => {
        //         updateDatas[`chat_rooms/${objKey}/users/${userId}/userData`] = filterUserData;
        //     });
        //     updateDatas[`users/${userId}/userData`] = filterUserData;
        //     try {
        //         await firebaseChatRoomUsersDataUpdate(updateDatas);
        //     } catch (err) {
        //         console.log(err, "更新聊天對象暱稱失敗");
        //     }
        // },
        /**
         * 更新 firebase firstore 聊天對象資料
         * @param { type String(字串) } userId 目前更新對象的 banana_id
         * @param { type Object(物件) } userData 更新完後最新的使用者資料
         */
        async updateFirebaseChatRoomUserData(userId, userData) {
            const chatRoomUsers = await firebaseChatRoomUserKeys(userId);
            // 沒有使用者資料時 不往下執行更新
            if (chatRoomUsers.length === 0) {
                return;
            }
            // 聊天對象更新關於自己的使用者資料
            await firebaseChatRoomUsersDataUpdate(userId, chatRoomUsers, userData);
            // 更新自己的使用者資料
            await firebaseUpdateChatRoomUserData(userId, userData);
        },
    },
};
